import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UsuarioGuard } from './guards/usuario.guard';

const routes: Routes = [
  {
    path: 'home',
    canLoad: [ UsuarioGuard ],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    canLoad: [ UsuarioGuard ],
    pathMatch: 'full'
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./terms-of-use/terms-of-use.module').then( m => m.TermsOfUsePageModule)
  },
  {
    path: 'passcode',
    loadChildren: () => import('./passcode/passcode.module').then( m => m.PasscodePageModule)
  },
  {
    path: 'add-coin',
    loadChildren: () => import('./add-coin/add-coin.module').then( m => m.AddCoinPageModule)
  },
  {
    path: 'bitcoin',
    loadChildren: () => import('./bitcoin/bitcoin.module').then( m => m.BitcoinPageModule)
  },
  {
    path: 'add-transaction',
    loadChildren: () => import('./add-transaction/add-transaction.module').then( m => m.AddTransactionPageModule)
  },
  {
    path: 'add-alert',
    loadChildren: () => import('./add-alert/add-alert.module').then( m => m.AddAlertPageModule)
  },
  {
    path: 'buyappalert',
    loadChildren: () => import('./buyappalert/buyappalert.module').then( m => m.BuyappalertPageModule)
  },
  {
    path: 'vt-popup',
    loadChildren: () => import('./vt-popup/vt-popup.module').then( m => m.VtPopupPageModule)
  },
  {
    path: 'change-language',
    loadChildren: () => import('./change-language/change-language.module').then( m => m.ChangeLanguagePageModule)
  },
  {
    path: 'profundidad',
    loadChildren: () => import('./profundidad/profundidad.module').then( m => m.ProfundidadPageModule)
  },
  {
    path: 'sigin',
    loadChildren: () => import('./sigin/sigin.module').then( m => m.SiginPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'withdraw',
    loadChildren: () => import('./withdraw/withdraw.module').then( m => m.WithdrawPageModule)
  },
  {
    path: 'remember-password',
    loadChildren: () => import('./remember-password/remember-password.module').then( m => m.RememberPasswordPageModule)
  },
  {
    path: 'profundidad-cripto',
    loadChildren: () => import('./profundidad-cripto/profundidad-cripto.module').then( m => m.ProfundidadCriptoPageModule)
  },
  {
    path: 'connect-to',
    loadChildren: () => import('./connect-to/connect-to.module').then( m => m.ConnectToPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
