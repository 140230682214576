import { Component, Inject } from '@angular/core';

import { NavController, Platform, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/models/contants.models';
import { MyEvent } from 'src/services/myevent.services';
import { APP_CONFIG, AppConfig } from './app.config'; 
import { VtPopupPage } from './vt-popup/vt-popup.page';
import { Storage } from '@ionic/storage-angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  rtlSide = "left";
  selectedIndex: any;
  lang: any='es';
  appPages: any;
  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private storage: Storage,
    private platform: Platform, private navCtrl: NavController,
    private modalController: ModalController, 
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService, private myEvent: MyEvent) {
    this.initializeApp();
    this.myEvent.getLanguageObservable().subscribe(value => {
      this.navCtrl.navigateRoot(['./']);
      this.globalize(value);
    });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      // this.statusBar.backgroundColorByHexString('');
      // this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString('#23353c');
      this.statusBar.overlaysWebView(false);

      this.splashScreen.hide();

      this.lang = await this.storage.get('lang');
      if(this.lang==null){
        window.localStorage.setItem(Constants.KEY_DEFAULT_LANGUAGE, 'es');
        this.lang ='es';
      }
      let defaultLang = this.lang;
      this.globalize(defaultLang);
    });
  }

  globalize(languagePriority) {
    this.translate.setDefaultLang(this.lang);
    let defaultLangCode = this.config.availableLanguages[0].code;
    this.translate.use(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
    this.setDirectionAccordingly(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
  }

  setDirectionAccordingly(lang: string) {
    switch (lang) {
      case 'ar': {
        this.rtlSide = "rtl";
        break;
      }
      default: {
        this.rtlSide = "ltr";
        break;
      }
    }
  }

  async ngOnInit() {
      // If using a custom driver:
      // await this.storage.defineDriver(MyCustomDriver)
    await this.storage.create();
    this.lang = await this.storage.get('lang') || null;

    if (this.config.demoMode) {
      setTimeout(() => {
        this.presentModal();
      }, 15000)
    }
  }    
async presentModal() {
    const modal = await this.modalController.create({
      component: VtPopupPage,
    });
    return await modal.present();
  }    
}
