import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UsuarioGuard implements CanLoad {
  constructor(private storage: Storage, private route: Router,private navCtrl: NavController) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.cargarUser();
  }
  async cargarUser(): Promise<boolean> {
    const token = await this.storage.get('token') || null;
    if ( token !== null ) {
      return Promise.resolve(true);
    } else {
      this.route.navigate(['./login']);
      return Promise.resolve(false);
    }
  }
}
